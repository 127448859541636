import React, { useState, useEffect } from "react";
import {
  Button,
  Card, Dimmer, Form, Grid, Icon, Loader,
} from "tabler-react";
import { Figure, Badge } from "react-bootstrap";
import { withI18n } from "react-i18next";

import ProductsService from "../../../services/domain/ProductsService";
import ErrorHandler from "../../../services/error/ErrorHandler";
import LinksProvider from "../../../services/http/LinksProvider";
import Editable from "../../common/editable/Editable";
import DateService from "../../../services/utils/DateService";
import { useToasts } from "react-toast-notifications";
import DropZone from "../../common/dropzone/DropZone";
import CurrencyService from '../../../services/utils/CurrencyService';
import FormatService  from "../../../services/utils/FormatService";

import {
  ProductType,
  ProductPlacement,
  ProductGender,
  ProductCategory,
  ProductStore,
  ProductAgeRange,
  ProductStatus,
  ProductSubCategory
} from "../../../models/Product";
import ProductInspirationLists from "../product-inspiration-lists/ProductInspirationLists";

const ProductInfo = ({ t, reference }) => {

  const [model, setModel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [multSelects, setMultSelects] = useState(null);
  const [showSelectsUpdate, setShowSelectsUpdate] = useState({ age_ranges: false, categories: false, stores: false });
  const [storesError, setStoresError] = useState(false);
  const [newCoverPhoto, setNewCoverPhoto] = useState(null);
  const [newProductPhotos, setNewProductPhotos] = useState([]);
  const [displayDropzone, setDisplayDropZone] = useState({ cover_dropzone: false, photos_dropzone: false });
  const { addToast } = useToasts();


  const componentConfig = {
    iconFiletypes: [".jpg", ".png", ".jpeg"],
    showFiletypeIcon: true,
    postUrl: (process.env.REACT_APP_BACKEND_BASE_URL || 'http://localhost:1337') + LinksProvider.API.PRODUCTS.UPLOAD_PHOTO
  };

  const coverConfig = {
    eventHandlers: {
      success: (file, response) => {
        if (response && response.data && response.data.image) {
          setNewCoverPhoto(response.data.image.url);
        }
      }
    },
    djsConfig: {
      maxFilesize: 10,
      maxFiles: 1,
      dictDefaultMessage: t('products.upload-cover-photo'),
      dictRemoveFile: t('products.remove-photo'),
      addRemoveLinks: true,
      acceptedFiles: "image/jpeg,image/jpg,image/png",
      maxfilesexceeded: function (file) {
        this.removeAllFiles();
        this.addFile(file);
      },
      sending: function (file, xhr, formData) {
        formData.append("image", file);
      }
    }
  };

  const productPhotosConfig = {
    eventHandlers: {
      success: (file, response) => {
        const oldPhotos = newProductPhotos;
        if (response && response.data && response.data.image) {
          oldPhotos.push(response.data.image.url);
          setNewProductPhotos(oldPhotos);
        }
      }
    },
    djsConfig: {
      maxFilesize: 10,
      maxFiles: 4,
      dictDefaultMessage: t('products.upload-product-photos'),
      dictRemoveFile: t('products.remove-photo'),
      addRemoveLinks: true,
      acceptedFiles: "image/jpeg,image/jpg,image/png",
      maxfilesexceeded: function (file) {
        this.removeFile(file);
      },
      sending: function (file, xhr, formData) {
        formData.append("image", file);
      }
    }
  };

  const [categoriesList, setCategoriesList] = useState([]);
  const [subCategories, setsubCategories] = useState({});

  useEffect(() => {
    ProductsService.getCategoriesAndSubCategories()
      .then((res) => {
        const result = Object.groupBy(res, ({ reference }) => reference);
        setCategoriesList(Object.keys(result).map(catKey => ({reference: catKey, id: res.find(r => !r.subcategory_reference && r.reference == catKey)?.id})));
        setsubCategories(result);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const loadData = async () => {
    setLoadingProduct(true);
    try {
      const product = await ProductsService.get(reference);
      setModel(product);
      setMultSelects({
        age_ranges: product.age_ranges && product.age_ranges.length ? product.age_ranges : [],
        stores: product.stores && product.stores.length ? product.stores : [],
        categories: product.categories && product.categories.length ? product.categories : [],
      });
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingProduct(false);
    }
  };

  useEffect(() => {
    if (categoriesList.length > 0 && Object.keys(subCategories).length > 0) {
      loadData();
    }
  }, [categoriesList, subCategories]);

  const handleUpdateMultSelects = (request) => {
    if (request.stores && request.stores.length == 0) {
      setStoresError(true);
    } else {
      updateProduct(request);
      setStoresError(false);
      setShowSelectsUpdate({ age_ranges: false, categories: false, stores: false});
      loadData()
    }
  };

  const handleUpdatePhotoUpdate = (request) => {
    updateProduct(request);
    setDisplayDropZone({ cover_dropzone: false, photos_dropzone: false });
  };

  const updateProduct = (request) => {
    setLoading(true);
    return ProductsService.update(model.reference, request)
      .then(product => {
        setModel({ ...model, ...product });
        loadData();
        return product;
      })
      .catch(err => {
        ErrorHandler.handleError(addToast, t, err);
        loadData();
      })
      .finally(() => setLoading(false));
  };

  const editInfoProduct = (field) => {
    return (val) => {
      const request = {};
      if (field.indexOf('.') != -1) {
        const array = field.split('.');
        model[array[0]][array[1]] = val;
        request[array[0]] = {};
        request[array[0]][array[1]] = val;
      } else {
        model[field] = val;
        if (field == "is_favorite" || field == "included_in_inspiration_list") {
          request[field] = val === true || val == "true" ? true : false;
        } else if (field == "validity_start_date" || field == "validity_end_date") {
          var dateParts = val.split("/");
          request[field] = dateParts.reverse().join('-');
        } else if (field == "price") {
          request[field] = CurrencyService.EuroToCents(val);
        } else if (field == "gender") {
          request[field] = val == 0 || val == 1 || val == 2 ? val : null;
        } else {
          request[field] = val;
        }
      }
      setModel({ ...model });
      updateProduct(request);
    }
  };


  return (<React.Fragment>
    {loadingProduct && (<Loader />)}
    {(model && !loadingProduct) && (<React.Fragment>
      <Grid.Row className={"text-left"} cards deck>
        <Grid.Col width={12}>
          <Card className="card-pot">
            <Card.Header>
              <Card.Title>
                {t('products.product-info')}
              </Card.Title>
            </Card.Header>
            <Card.Body className="text-center">
              <Dimmer active={loading} loader>
                <Grid.Row>
                  <Grid.Col width={3}>
                    <Form.Group label={t('products.reference')} className={"text-left"}>
                      {model.reference}
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={3}>
                    <Form.Group label={t('products.name')} className={"text-left"}>
                      <Editable
                        initialValue={model.name}
                        undefinedText={t('globals.no-value')}
                        isValueClickable={true}
                        onSubmit={editInfoProduct('name')}
                        type="textfield" />
                    </Form.Group>
                  </Grid.Col>

                  <Grid.Col width={3}>
                    <Form.Group label={t('products.brand')} className={"text-left"}>
                      <Editable
                        initialValue={model.brand}
                        undefinedText={t('globals.no-value')}
                        isValueClickable={true}
                        onSubmit={editInfoProduct('brand')}
                        type="textfield"
                      />
                    </Form.Group>
                  </Grid.Col>

                  <Grid.Col width={3}>
                    <Form.Group label={t('products.type-choice')} className={"text-left"}>
                      <Editable
                        initialValue={model.type}
                        undefinedText={t('globals.no-value')}
                        isValueClickable={true}
                        onSubmit={editInfoProduct('type')}
                        type="select" options={Object.keys(ProductType).map(key => {
                          return {
                            label: t(`products.type_${ProductType[key]}`), value: ProductType[key]
                          }
                        })} />
                    </Form.Group>
                  </Grid.Col>

                </Grid.Row>
                <br />
                <Grid.Row>

                  <Grid.Col width={3}>
                    <Form.Group label={t('products.price_in_euros')} className={"text-left"}>
                      <Editable
                        initialValue={CurrencyService.centsToEuro(model.price)}
                        undefinedText={t('products.event-date')}
                        isValueClickable={true}
                        onSubmit={editInfoProduct('price')}
                        type="number"
                      />
                    </Form.Group>
                  </Grid.Col>

                  <Grid.Col width={3}>
                    <Form.Group label={t('products.placement')} className={"text-left"}>
                      <Editable
                        initialValue={model.placement}
                        undefinedText={t('globals.no-value')}
                        isValueClickable={true}
                        onSubmit={editInfoProduct('placement')}
                        type="select" options={Object.keys(ProductPlacement).map(key => {
                          return {
                            label: t(`products.placement_${ProductPlacement[key]}`), value: ProductPlacement[key]
                          }
                        })} />
                    </Form.Group>
                  </Grid.Col>

                  <Grid.Col width={3}>
                    <Form.Group label={t('products.gender')} className={"text-left"}>
                      <Editable
                        initialValue={model.gender} // TODO
                        undefinedText={t('products.gender_null')}
                        isValueClickable={true}
                        onSubmit={editInfoProduct('gender')}
                        type="select" options={Object.keys(ProductGender).map(key => {
                          return {
                            label: t(`products.gender_${ProductGender[key]}`), value: ProductGender[key]
                          }
                        })}
                      />
                    </Form.Group>
                  </Grid.Col>

                  <Grid.Col width={3}>
                    <Form.Group label={t('products.is_favorite')} className={"text-left"}>
                      <Editable
                        initialValue={model.is_favorite}
                        undefinedText={t('globals.no-value')}
                        isValueClickable={true}
                        onSubmit={editInfoProduct('is_favorite')}
                        type="select" options={[{ label: 'Oui', value: true }, {
                          label: 'Non', value: false
                        },]} />
                    </Form.Group>
                  </Grid.Col>

                </Grid.Row>
                <br />
                <Grid.Row>
                  <Grid.Col width={6}>
                    <Form.Group label={t('products.first_url')} className={"text-left"}>
                      <Editable
                        initialValue={model.first_url}
                        undefinedText={t('globals.no-value')}
                        isValueClickable={true}
                        onSubmit={editInfoProduct('first_url')}
                        type="textfield" />
                    </Form.Group>
                  </Grid.Col>

                  <Grid.Col width={6}>
                    <Form.Group label={t('products.second_url')} className={"text-left"}>
                      <Editable
                        initialValue={model.second_url}
                        undefinedText={t('globals.no-value')}
                        isValueClickable={true}
                        onSubmit={editInfoProduct('second_url')}
                        type="textfield" />
                    </Form.Group>
                  </Grid.Col>

                </Grid.Row>
                <Grid.Row>
                  <Grid.Col width={4}>
                    <Form.Group label={t('products.status')} className={"text-left"}>
                      <Editable
                        initialValue={model.status} // TODO
                        undefinedText={t('globals.no-value')}
                        isValueClickable={true}
                        onSubmit={editInfoProduct('status')}
                        type="select" options={Object.keys(ProductStatus).map(key => {
                          return {
                            label: t(`products.status_${ProductStatus[key]}`), value: ProductStatus[key]
                          }
                        })}
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={4}>
                    <Form.Group label={t('products.included_in_inspiration_list')} className={"text-left"}>
                      <Editable
                        initialValue={model.included_in_inspiration_list} // TODO
                        undefinedText={t('globals.no-value')}
                        isValueClickable={true}
                        onSubmit={editInfoProduct('included_in_inspiration_list')}
                        type="select"
                        options={[{ label: 'Oui', value: true }, { label: 'Non', value: false }]
                        } />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col width={4}>
                    <Form.Group label={t('products.validity_start_date')} className={"text-left"}>
                      <Editable
                        initialValue={model.validity_start_date}
                        isValueClickable={true}
                        type='date'
                        onSubmit={editInfoProduct('validity_start_date')}
                      />
                    </Form.Group>
                  </Grid.Col>

                  <Grid.Col width={4}>
                    <Form.Group label={t('products.validity_end_date')} className={"text-left"}>
                      <Editable
                        initialValue={model.validity_end_date}
                        isValueClickable={true}
                        type='date'
                        onSubmit={editInfoProduct('validity_end_date')}
                      />
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col width={4}>
                    <Form.Group label={t('products.creation_date')} className={"text-left"}>
                      {DateService.formatTime(model.creation_date)}
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Col width={12}>
                    <Form.Group label={t('products.description')} className={"text-left"}>
                      <Editable
                        undefinedText={t('globals.no-value')}
                        initialValue={model.description}
                        isValueClickable={true}
                        type="textarea"
                        onSubmit={editInfoProduct('description')}
                      />
                    </Form.Group>
                  </Grid.Col>
                </Grid.Row>

              </Dimmer>
            </Card.Body>
          </Card>
        </Grid.Col>

        <Grid.Col width={6}>

          <Card>
            <Card.Header>
              <Card.Title>{t('products.product-photos')}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Dimmer active={loading} loader>
                <Grid.Row>
                  <Grid.Col width={12}>
                    <Form onSubmit={(e) => {
                      e.preventDefault();
                      if (newCoverPhoto) handleUpdatePhotoUpdate({ cover_photo: newCoverPhoto })
                    }}>
                      <Form.Group label={t('products.cover-photo')} className={"text-left"}>
                        {(!displayDropzone.cover_dropzone) ? (
                          <div>
                            {(model.cover_photo && model.cover_photo.storage_service_url) ? (
                              <div>
                                <Grid.Row>
                                  <Grid.Col width={12}>
                                    <Figure>
                                      <Figure.Image
                                        alt={t('products.cover-photo')}
                                        width={300}
                                        height={250}
                                        src={model.cover_photo.storage_service_url} />
                                    </Figure>
                                  </Grid.Col>
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Col width={12}>
                                    <Button color="primary" onClick={(e) => setDisplayDropZone({
                                      ...displayDropzone,
                                      cover_dropzone: true
                                    })}>{t('products.update')}</Button>
                                  </Grid.Col>
                                </Grid.Row>
                              </div>
                            ) :
                              (<div>
                                <h3>
                                  <Badge variant="secondary">{t('products.no-cover-photo')}</Badge>
                                </h3>
                                <Button color="primary" onClick={(e) => setDisplayDropZone({
                                  ...displayDropzone,
                                  cover_dropzone: true
                                })}>{t('products.add')}</Button>
                              </div>)}
                          </div>
                        ) : (
                          <DropZone
                            componentConfig={componentConfig}
                            dropZoneConfig={coverConfig.djsConfig}
                            eventHandlers={coverConfig.eventHandlers}
                          />
                        )}
                        <br />
                        {(displayDropzone.cover_dropzone) ? (
                          <React.Fragment>
                            <Button type="button" size="sm" color="danger"
                              onClick={(e) => {
                                setDisplayDropZone({ ...displayDropzone, cover_dropzone: false });
                                setNewCoverPhoto(null)
                              }}>
                              <Icon prefix="fa" name="remove" />
                            </Button>&nbsp;
                            <Button type="submit" size="sm" color="success" icon="check" />
                          </React.Fragment>) : null}
                      </Form.Group>
                    </Form>
                  </Grid.Col>

                  <Grid.Col width={12}>
                    <Form onSubmit={(e) => {
                      e.preventDefault();
                      if (newProductPhotos) handleUpdatePhotoUpdate({ photos: newProductPhotos })
                    }}>
                      <Form.Group label={t('products.product-photos')} className={"text-left"}>
                        {(!displayDropzone.photos_dropzone) ? (
                          <div>
                            {(model.photos && model.photos.length) ? (
                              <div>
                                <Grid.Row>
                                  {model.photos.map(photo =>
                                    <Grid.Col width={6}>
                                      <Figure>
                                        <Figure.Image
                                          alt={t('products.product-photos')}
                                          width={171}
                                          height={180}
                                          src={photo.storage_service_url} />
                                      </Figure>
                                    </Grid.Col>
                                  )}
                                </Grid.Row>
                                <Grid.Row>
                                  <Grid.Col width={12}>
                                    <Button color="primary" onClick={(e) => setDisplayDropZone({
                                      ...displayDropzone,
                                      photos_dropzone: true
                                    })}>{t('products.update')}</Button>
                                  </Grid.Col>
                                </Grid.Row>
                              </div>
                            ) :
                              (<div>
                                <h3>
                                  <Badge variant="secondary">{t('products.no-photos')}</Badge>
                                </h3>
                                <Button color="primary" onClick={(e) => setDisplayDropZone({
                                  ...displayDropzone,
                                  photos_dropzone: true
                                })}>{t('products.add')}</Button>
                              </div>)}
                          </div>
                        ) : (
                          <DropZone
                            componentConfig={componentConfig}
                            dropZoneConfig={productPhotosConfig.djsConfig}
                            eventHandlers={productPhotosConfig.eventHandlers}
                          />
                        )}
                        <br />
                        {(displayDropzone.photos_dropzone) ? (
                          <React.Fragment>
                            <Button type="button" size="sm" color="danger"
                              onClick={(e) => {
                                setDisplayDropZone({ ...displayDropzone, photos_dropzone: false });
                                setNewProductPhotos(null)
                              }}>
                              <Icon prefix="fa" name="remove" />
                            </Button>&nbsp;
                            <Button type="submit" size="sm" color="success" icon="check" />
                          </React.Fragment>) : null}
                      </Form.Group>
                    </Form>
                  </Grid.Col>
                </Grid.Row>
              </Dimmer>
            </Card.Body>
          </Card>
        </Grid.Col>

        <Grid.Col width={6}>
          <Card>
            <Card.Header>
              <Card.Title>{t('products.other-info')}</Card.Title>
            </Card.Header>
            <Card.Body>
              <Dimmer active={loading} loader>
                <Grid.Row>
                  <Grid.Col width={6}>
                    <Form onSubmit={(e) => {
                      e.preventDefault();
                      handleUpdateMultSelects({ age_ranges: multSelects.age_ranges })
                    }}>
                      <Form.Group label={t('products.age_ranges.title')} className={"text-left"}>
                        {Object.values(ProductAgeRange).map(value =>

                          <Form.Checkbox
                            label={t('products.age_ranges.age_range_' + value)}
                            name="age_ranges"
                            onChange={(e) => {
                              const oldRanges = multSelects;
                              var index = oldRanges.age_ranges.indexOf(value);
                              if (index !== -1) {
                                oldRanges.age_ranges.splice(index, 1);
                              } else {
                                oldRanges.age_ranges.push(value);
                              }
                              setMultSelects({ ...oldRanges });
                              setShowSelectsUpdate({ ...showSelectsUpdate, age_ranges: true });
                            }}
                            checked={multSelects.age_ranges.indexOf(value) !== -1}
                          />)}
                      </Form.Group>
                      {(showSelectsUpdate.age_ranges) ? (
                        <React.Fragment>
                          <Button type="button" size="sm" color="danger" onClick={(e) => {
                            e.preventDefault();
                            window.location.reload()
                          }}>
                            <Icon prefix="fa" name="remove" />
                          </Button>&nbsp;
                          <Button type="submit" size="sm" color="success" icon="check" />
                        </React.Fragment>) : null}
                    </Form>
                  </Grid.Col>


                  <Grid.Col width={6}>
                    <Form onSubmit={(e) => {
                      e.preventDefault();
                      handleUpdateMultSelects({ stores: multSelects.stores })
                    }}>
                      <Form.Group label={t('products.stores.title')} className={"text-left"}>
                        {Object.values(ProductStore).map(value =>
                          <Form.Checkbox
                            label={t('products.stores.store_' + value)}
                            name="stores"
                            onChange={(e) => {
                              const oldStores = multSelects;
                              var index = oldStores.stores.indexOf(value);
                              if (index !== -1) {
                                oldStores.stores.splice(index, 1);
                              } else {
                                oldStores.stores.push(value);
                              }
                              setMultSelects({ ...oldStores });
                              setShowSelectsUpdate({ ...showSelectsUpdate, stores: true });
                            }}
                            checked={multSelects.stores.indexOf(value) !== -1}
                          />)}
                      </Form.Group>
                      {(showSelectsUpdate.stores) ? (
                        <React.Fragment>
                          <Button type="button" size="sm" color="danger" onClick={(e) => {
                            e.preventDefault();
                            window.location.reload()
                          }}>
                            <Icon prefix="fa" name="remove" />
                          </Button>&nbsp;
                          <Button type="submit" size="sm" color="success" icon="check" />
                        </React.Fragment>) : null}

                      {storesError ? (
                        <div>
                          <br />
                          <p className="text-danger">{t('products.at-least-one-store')}</p>
                        </div>
                      ) : null}
                    </Form>
                  </Grid.Col>

                  <Grid.Col width={6}>
                    <Form onSubmit={(e) => {
                      e.preventDefault();
                      handleUpdateMultSelects({ categories: multSelects.categories });
                    }}>
                      <Form.Group label={t('products.categories.title')} className={"text-left"}>

                        {categoriesList?.map((category, index) => (
                          <>
                            <div>
                                <Form.Checkbox
                                    key={category.reference}
                                    label={FormatService.kebabToUpper(category.reference).replace('_', ' ')}
                                    name="category"
                                    onChange={() => {
                                      const oldSubCategories = {...multSelects}
                                      let index = oldSubCategories.categories.indexOf(category.id)
                                      if (index !== -1) {
                                        oldSubCategories.categories.splice(index, 1);
                                      } else {
                                        oldSubCategories.categories.push(category.id);
                                      }

                                      setMultSelects(oldSubCategories);
                                      setShowSelectsUpdate({ ...showSelectsUpdate, categories: true });
                                    }}
                                    checked={multSelects.categories?.indexOf(category.id) !== -1}
                                  />
                            </div>
                            {
                              subCategories[category.reference]?.map((subcategory) => (
                                <div style={{marginLeft: "20px"}}>
                                  <Form.Checkbox
                                    key={subcategory.id}
                                    label={FormatService.kebabToUpper(subcategory.subcategory_name)}
                                    name="subcategory"
                                    onChange={(e) => {
                                      const oldSubCategories = {...multSelects}
                                      let index = oldSubCategories.categories.indexOf(subcategory.id)
                                      if (index !== -1) {
                                        oldSubCategories.categories.splice(index, 1);
                                      } else {
                                        oldSubCategories.categories.push(subcategory.id);
                                      }

                                      setMultSelects(oldSubCategories);
                                      setShowSelectsUpdate({ ...showSelectsUpdate, categories: true });
                                    }}
                                    checked={multSelects.categories?.indexOf(subcategory.id) !== -1}
                                  />
                                </div>
                              ))}
                          </>
                        ))}

                        {(showSelectsUpdate.categories) ? (
                          <React.Fragment>
                            <Button type="button" size="sm" color="danger" onClick={(e) => {
                              e.preventDefault();
                              window.location.reload();
                            }}>
                              <Icon prefix="fa" name="remove" />
                            </Button>
                            &nbsp;
                            <Button type="submit" size="sm" color="success" icon="check" />
                          </React.Fragment>
                        ) : null}
                      </Form.Group>
                    </Form>

                  </Grid.Col>
                </Grid.Row>
                <Grid.Row className="mt-5">
                  <Grid.Col width={12}>
                    <Dimmer active={loading}>


                      {!loading && <ProductInspirationLists productReference={model.reference} productType={model.type} />}
                    </Dimmer>
                  </Grid.Col>
                </Grid.Row>
              </Dimmer>
            </Card.Body>
          </Card>
        </Grid.Col>
      </Grid.Row>
    </React.Fragment>)}

  </React.Fragment>)
};

export default withI18n()(ProductInfo);
